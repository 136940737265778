/* eslint-disable react/react-in-jsx-scope */

import { ExternalLinkButton } from '../buttons/ExternalLinkButton';
import { SignOutButton } from '../buttons/SignOutButton';

import confluenceLogo from '../images/Confluence.png';
import jiraLogo from '../images/Jira.png';
import gatherLogo from '../images/Gather.png';
import gitlabLogo from '../images/Gitlab.png';
import personioLogo from '../images/Personio.png';
import teamsLogo from '../images/teams.png';
import mocoLogo from '../images/moco.png';
import officeLogo from '../images/office.png';
import xaplingLogo from '../images/xapling_logo.png';
import getMossLogo from '../images/Getmoss.png';
import { Grid } from '@mui/material';

export function Startpage(): React.JSX.Element {
  return (
    <>
      <header>
        <img className="xapling-logo" src={xaplingLogo} width={100} height={100} />
        <SignOutButton />
      </header>
      <div id="container">
        <h1 className="headline">Willkommen bei xapling</h1>
        <Grid className="grid-container" container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <ExternalLinkButton
              externalLink={'/auslagenerstattung/'}
              linkName={'Auslagenerstattung'}
              logo={xaplingLogo}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ExternalLinkButton externalLink={'/bewirtungsbeleg/'} linkName={'Bewirtungsbeleg'} logo={xaplingLogo} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ExternalLinkButton externalLink={'/ausschreibung/'} linkName={'Ausschreibung'} logo={xaplingLogo} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ExternalLinkButton
              externalLink={'https://xapling.atlassian.net/wiki/spaces/LUP/overview?homepageId=424902717'}
              linkName={'Confluence'}
              logo={confluenceLogo}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ExternalLinkButton
              externalLink={'https://xapling.atlassian.net/jira/dashboards/10000'}
              linkName={'Jira'}
              logo={jiraLogo}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ExternalLinkButton
              externalLink={'https://app.gather.town/app/afDtAr5IKEoGrGpC/xapling'}
              linkName={'Gather'}
              logo={gatherLogo}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ExternalLinkButton externalLink={'https://gitlab.com/xapling'} linkName={'Gitlab'} logo={gitlabLogo} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ExternalLinkButton
              externalLink={'https://xapling-gmbh.personio.de/'}
              linkName={'Personio'}
              logo={personioLogo}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ExternalLinkButton externalLink={'https://teams.microsoft.com/'} linkName={'Teams'} logo={teamsLogo} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ExternalLinkButton externalLink={'https://xaplinggmbh.mocoapp.com'} linkName={'Moco'} logo={mocoLogo} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ExternalLinkButton
              externalLink={'https://outlook.office.com/mail/'}
              linkName={'Office'}
              logo={officeLogo}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <ExternalLinkButton externalLink={'https://getmoss.com/login'} linkName={'Moss'} logo={getMossLogo} />
          </Grid>
        </Grid>
      </div>
      <footer></footer>
    </>
  );
}
