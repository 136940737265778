import { Configuration, LogLevel } from '@azure/msal-browser';

/**
 * Configuration object to be passed to MSAL instance on creation. For a full list of MSAL.js
 * configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
  auth: {
    clientId: '09f3be27-c701-4a4e-bc65-cfe64c26c966',
    authority: 'https://login.microsoftonline.com/6356273b-4a3e-494b-be5a-0f7a1108ade4',
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in. By default, MSAL.js will
 * add OIDC scopes (openid, profile, email) to any login request. For more information about OIDC
 * scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: ['api://09f3be27-c701-4a4e-bc65-cfe64c26c966/Access.API'],
};
