/* eslint-disable react/react-in-jsx-scope */

import axios from 'axios';
import { useEffect, useState } from 'react';

const baseURL = 'https://inspirobot.me/api?generate=true';

export function ErrorPage(): React.JSX.Element {
  const [image, setImage] = useState('');

  useEffect(() => {
    axios
      .get<string>(baseURL)
      .then((response): void => {
        setImage(response.data);
      })
      .catch(console.error);
  }, []);

  return (
    <div>
      <h1 style={{ textAlign: 'center' }}>Diese Seite existiert nicht, sorry</h1>
      <img src={image}></img>
    </div>
  );
}
