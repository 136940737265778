/* eslint-disable react/react-in-jsx-scope */
import { useMsal } from '@azure/msal-react';
import 'font-awesome/css/font-awesome.min.css';

export function SignOutButton(): React.JSX.Element {
  const { instance } = useMsal();

  return (
    <div
      className="logoutButton"
      onClick={(): void =>
        void instance.logoutPopup({
          postLogoutRedirectUri: '/',
          mainWindowRedirectUri: '/',
        })
      }
    >
      <div className="logoutIcon">
        <i className="fa fa-sign-out" aria-hidden="true"></i>
      </div>
      <div className="logoutText">Logout</div>
    </div>
  );
}
