import './App.css';
import { Home } from './pages/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ErrorPage } from './pages/ErrorPage';
import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { addAxiosAuthInterceptor, setActiveAccountOnLogin, setCurrentAccountActive } from './modules/Auth';

function App(): React.JSX.Element {
  const { instance } = useMsal();
  useEffect(() => {
    setCurrentAccountActive(instance);
    setActiveAccountOnLogin(instance);
    addAxiosAuthInterceptor(instance);
  }, [instance]);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
