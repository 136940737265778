/* eslint-disable react/react-in-jsx-scope */
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import 'font-awesome/css/font-awesome.min.css';

export function SignInButton(): React.JSX.Element {
  const { instance } = useMsal();

  return (
    <div
      id="loginButton"
      className="loginButton"
      onClick={(): void => {
        instance.loginPopup(loginRequest).catch((e) => {
          console.log(e);
        });
      }}
    >
      <div className="loginIcon">
        <i className="fa fa-sign-in" aria-hidden="true"></i>
      </div>
      <div className="loginText">Login</div>
    </div>
  );
}
