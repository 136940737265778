import { PublicClientApplication, UrlString } from '@azure/msal-browser';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { msalConfig } from './authConfig';
import axios from 'axios';
import { MsalProvider } from '@azure/msal-react';

const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('Could not find root element');
}
const root = ReactDOM.createRoot(rootElement);

/**
 * We need to determine if we are in the auth popup If so, we need to render a simple message
 * Otherwise, we render the app [MSAL
 * uses]{@link (https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/src/interaction_client/PopupClient.ts#L343)}
 * "UrlString.hashContainsKnownProperties" method to validate the hash
 *
 * @returns True if the current window is an auth popup
 */
function isAuthPopup(): boolean {
  const hash = window.location.hash;
  return UrlString.hashContainsKnownProperties(hash);
}

// check if we are in the auth popup
// if so, render a simple message
// otherwise, render the app
if (isAuthPopup()) {
  root.render(
    <div>
      <h1>Authenticating...</h1>
    </div>
  );
} else {
  if (process.env.REACT_APP_BASE_URL) {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
  }

  const msalInstance = new PublicClientApplication(msalConfig);
  root.render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </React.StrictMode>
  );
}
