/* eslint-disable react/react-in-jsx-scope */

import { SignInButton } from '../buttons/SignInButton';
import { ExternalLinkButton } from '../buttons/ExternalLinkButton';
import xaplingLogo from '../images/xapling_logo.png';
import { Grid } from '@mui/material';

export function LoginPage(): React.JSX.Element {
  return (
    <>
      <header>
        <img className="xapling-logo" alt="Xapling Logo" src={xaplingLogo} width={100} height={100} />
        <SignInButton />
      </header>
      <div id="container">
        <h1 className="headline">Willkommen bei xapling</h1>
        <Grid item xs={12} sm={6} md={4}>
          <ExternalLinkButton externalLink={'https://xapling.de/'} linkName={'Webseite xapling'} logo={xaplingLogo} />
        </Grid>
      </div>
      <footer></footer>
    </>
  );
}
