import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionRequiredAuthError,
  IPublicClientApplication,
} from '@azure/msal-browser';
import axios, { InternalAxiosRequestConfig } from 'axios';
import { loginRequest } from '../authConfig';

/**
 * Lade eventuell vorhandene Accounts aus der Sitzung und setzte den ersten aktiv.
 *
 * @param msalInstance MsalInstance
 */
export function setCurrentAccountActive(msalInstance: IPublicClientApplication): void {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    console.log(`Found ${accounts.length} accounts`);
    msalInstance.setActiveAccount(accounts[0]);
  }
}

/**
 * Setzt beim Login den aktuellen Account als aktiv, damit dieser in {@link #addAxiosAuthInterceptor}
 * verwendet werden kann.
 *
 * @param msalInstance MsalInstance
 */
export function setActiveAccountOnLogin(msalInstance: IPublicClientApplication): void {
  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
    }
  });
}

/**
 * Fügt den Requests den aktuellen accessToken aus dem Session Storage hinzu.
 *
 * @param msalInstance MsalInstance
 * @see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/acquire-token.md
 */
export function addAxiosAuthInterceptor(msalInstance: IPublicClientApplication): void {
  axios.interceptors.request.use(async (config): Promise<InternalAxiosRequestConfig> => {
    return msalInstance
      .acquireTokenSilent({ ...loginRequest, account: msalInstance.getActiveAccount() ?? undefined })
      .catch(async (error) => {
        if (error instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          return msalInstance.acquireTokenPopup(loginRequest);
        }
        throw error;
      })
      .then((tokenResponse) => {
        config.headers['Authorization'] = 'Bearer ' + tokenResponse.accessToken;
        return config;
      });
  });
}
