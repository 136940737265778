import axios from 'axios';

interface ExternalButtonType {
  externalLink: string | URL;
  linkName: string;
  logo: string;
}

export function ExternalLinkButton({ externalLink, linkName, logo }: ExternalButtonType): React.JSX.Element {
  function handleClick(): void {
    if (linkName == 'Auslagenerstattung' || linkName == 'Bewirtungsbeleg') {
      warmUp(linkName);
    }
    window.open(externalLink);
  }

  function warmUp(service: string): void {
    const servieUrl = '/api/' + service.toLowerCase() + '/warmUp';
    void axios.get(servieUrl).then((res) => console.log(res));
  }

  return (
    <section className="startseite-button" onClick={(): void => handleClick()}>
      <img src={logo} width="20%" />
      <p className="startseite-button-text">{linkName}</p>
    </section>
  );
}
