/* eslint-disable react/react-in-jsx-scope */

import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { LoginPage } from './LoginPage';
import { Startpage } from './Startpage';

export function Home(): React.JSX.Element {
  return (
    <>
      <AuthenticatedTemplate>
        <Startpage />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <LoginPage />
      </UnauthenticatedTemplate>
    </>
  );
}
